import React from 'react';
import PropTypes from 'prop-types';

import TeamMember from './TeamMember';

const TeamMembers = ({ data }) => {
  return(
    <div className="rn-team-wrapper ptb--120 bg_color--5">
      <div className="rn-team-area">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title text-center mb--30">
                {data?.title && <h2>{data.title}</h2>}
                {data?.subtitle?.processed && <div dangerouslySetInnerHTML={{ __html: data.subtitle.processed }}></div>}
              </div>
            </div>
          </div>
          <div className="row">
            {data.relationships.members.map((member, index) => (
              <div className={`${'col-lg-2'}`} key={index}>
                <TeamMember data={member} />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

TeamMembers.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string,
    subtitle: PropTypes.shape({
      processed: PropTypes.string
    }),
    relationships: PropTypes.shape({
      members: PropTypes.array
    })
  })
};

export default TeamMembers;
