import Banner from '../components/Sections/Banner/Banner';
//import gallery from '../components/Sections/Banner/gallery';
import ContactChannels from '../components/Sections/Contact/ContactChannels';
import CardGrid from '../components/Sections/Cards/CardGrid';
import CoverFull from '../components/Sections/Cover/CoverFull';
import CoverLite from '../components/Sections/Cover/CoverLite';
import FactsGrid from '../components/Sections/Facts/FactsGrid';
import FeaturedBlogPosts from '../components/Sections/Blog/FeaturedBlogPosts';
import HubspotContactForm from '../components/Sections/Contact/HubspotContactForm';
import ImageText from '../components/Sections/ImageText';
import LogosSet from '../components/Sections/LogosSet';
import Projects from '../components/Sections/Projects/Projects';
import TeamMembers from '../components/Sections/TeamMembers/TeamMembers';

const sectionTypes = {
  paragraph__banner: Banner,
  //paragraph__banner: gallery,
  paragraph__blog: FeaturedBlogPosts,
  paragraph__contact_channels: ContactChannels,
  paragraph__card_grid: CardGrid,
  paragraph__contact_form: HubspotContactForm,
  paragraph__cover: CoverFull,
  paragraph__cover_lite: CoverLite,
  paragraph__facts_grid: FactsGrid,
  paragraph__image_text: ImageText,
  paragraph__projects: Projects,
  paragraph__team_members: TeamMembers,
  paragraph__logos_set: LogosSet
};

export const getSectionComponent = section => {
  if (section.type && sectionTypes[section.type] && section.active) {
    return sectionTypes[section.type];
  } else {
    return null;
  }
};
