import React from 'react';
import PropTypes from 'prop-types';
import IconComponent from '../../Social/IconComponent';

const Cards = ({ data }) => {
  return (
    <div className="row">
      {data.map((val, i) => (
        <div className='col-lg-4 col-md-6 col-sm-6 col-12 text-left' key={i}>
          <a href="/service-details">
            <div className="service service__style--2">
              <div className="icon">
                <IconComponent name={val.relationships.icon.slug} />
              </div>
              <div className="content">
                <h3 className="title">{val.title}</h3>
                {val?.summary?.processed && <div className="description" dangerouslySetInnerHTML={{ __html: val.summary.processed }}></div>}
              </div>
            </div>
          </a>
        </div>
      ))}
    </div>
  );
};

Cards.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string.isRequired,
    summary: PropTypes.shape({
      processed: PropTypes.string
    }),
    relationships: PropTypes.shape({
      icon: PropTypes.shape({
        slug: PropTypes.string.isRequired,
      })
    })
  }))
};



export default Cards;
