import React from 'react';
import { arrayOf, object, shape } from 'prop-types';

import FeaturedBlogPost from './FeaturedBlogPost';

const FeaturedBlogPosts = ({ data }) => {
  return(
    <div className="portfolio-area pt--90 pb--140 bg_color--1">
      <div className="rn-slick-dot">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="slick-space-gutter--15 slickdot--20">
                <div className="slick-slider slick-initialized" dir="ltr">
                  <div className="slick-list">
                    <div className="slick-track" style={{ width: '6300px', opacity: 1 }}>
                      {data?.relationships?.blogposts.map((blogpost, index) => (<FeaturedBlogPost key={index} post={blogpost} />))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

FeaturedBlogPosts.propTypes = {
  data: shape({
    relationships: shape({
      blogposts: arrayOf(object)
    })
  })
};

export default FeaturedBlogPosts;
