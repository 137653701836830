import React from 'react';
import PropTypes from 'prop-types';
import Facts from './Facts';

const FactsGrid = ({ data }) => {
  return (
    <div className="rn-counterup-area pt--25 pb--110 bg_color--1">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="section-title text-center">
              {data?.title && <h3 className="fontWeight500">{data.title}</h3>}
            </div>
          </div>
        </div>
        <Facts data={data.relationships.facts} />
      </div>
    </div>
  );
};

FactsGrid.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string,
    relationships: PropTypes.shape({
      facts: PropTypes.arrayOf(PropTypes.shape({
        number: PropTypes.number.isRequired,
        summary: PropTypes.shape({
          processed: PropTypes.string
        })
      }))
    })
  })
};

export default FactsGrid;
