import React from 'react';
import PropTypes from 'prop-types';
import BackgroundImage from 'gatsby-background-image';

import { MediaImageType } from '../../../types';

const CoverLite = ({ data }) => {
  return (
    <div className="hero image-hero">
        <BackgroundImage
          Tag="div"
          className="image-hero-background"
          fluid={data.relationships.image.relationships.image.localFile.childImageSharp.fluid}
          backgroundColor={`#040e18`}
          >
          <div className="hero-body image-hero-inner">
            <div className="container">
              {data?.title && <h2 className="title theme-gradient">{data.title}</h2>}
              {data?.body?.processed &&<span dangerouslySetInnerHTML={{ __html: data.body.processed }}></span>}
            </div>
          </div>
        </BackgroundImage>
    </div>
  );

};

CoverLite.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string,
    body: PropTypes.shape({
      processed: PropTypes.string
    }),
    relationships: PropTypes.shape({
      image: MediaImageType
    })
  })
};

export default CoverLite;
