import React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';

import Layout from '../components/Layout/Layout';
import AnimatedPageSections from '../components/Sections/AnimatedPageSections';
import SEO from '../components/SEO/SEO';
import { MetatagType } from '../types';

import '../fragments/metatags/pageMetatag';
import '../fragments/paragraphs/banner';
import '../theme/easytech/index.scss';

const HomePage = ({ data, location }) => {
  const sections = data?.home?.relationships?.sections || [];
  const sectionsIds = sections.map(section => section.id).filter(Boolean);

  return (
    <Layout className="home" sections={sectionsIds} location={location}>
      <SEO metatags={data.home?.metatags}/>
      <AnimatedPageSections sections={sections}/>
      <div className='icon-scroll'></div>
    </Layout>
  );
};

HomePage.propTypes = {
  data: PropTypes.shape({
    home: PropTypes.shape({
      title: PropTypes.string,
      metatags: PropTypes.arrayOf(MetatagType),
      relationships: PropTypes.shape({
        sections: PropTypes.array
      })
    })
  }),
  location: PropTypes.object
};

export default HomePage;

export const query = graphql`
  query HomepageQuery {
    home: nodePage(path: {alias: {eq: "/home"}}) {
      title
      metatags: metatag_normalized {
        ...PageMetatag
      }
      relationships {
        sections: field_page_sections {
          ...ParagraphBanner
        }
      }
    }
  }
`;
