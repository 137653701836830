import React from 'react';
import PropTypes from 'prop-types';
import Cards from './Cards';

const CardGrid = ({ data }) => {
  return (
    <section className="section">
      <div className="container">
        <div className="">
          {data?.title && <h2 className="title">{data.title}</h2>}
          {data?.summary?.processed && <div className="description" dangerouslySetInnerHTML={{ __html: data.summary.processed }}></div>}
        </div>
        <div className="creative-service">
          <div className="">
            <Cards item="6" column="add-class-names-cars" data={data.relationships.cards} />
          </div>
        </div>
      </div>
    </section>
  );
};

CardGrid.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string,
    summary: PropTypes.shape({
      processed: PropTypes.string
    }),
    relationships: PropTypes.shape({
      cards: PropTypes.arrayOf(PropTypes.shape({
        title: PropTypes.string.isRequired,
        summary: PropTypes.shape({
          processed: PropTypes.string
        }),
        relationships: PropTypes.shape({
          icon: PropTypes.shape({
            slug: PropTypes.string.isRequired
          })
        })
      }))
    })
  })
};

export default CardGrid;
