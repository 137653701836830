import React from 'react';
import { FaGithub, FaDrupal, FaLinkedinIn, FaTwitter, FaInstagram } from 'react-icons/fa';
import PropTypes from 'prop-types';

import { MediaImageType } from '../../../types';
import MediaImage from '../../Elements/MediaImage';

const socialLinks = {
  drupal: { url: 'https://www.drupal.org/u', icon: <FaDrupal /> },
  github: { url: 'https://github.com', icon: <FaGithub /> },
  instagram: { url: 'https://www.instagram.com', icon: <FaInstagram /> },
  linkedin: { url: 'https://www.linkedin.com', icon: <FaLinkedinIn /> },
  twitter: { url: 'https://www.twitter.com', icon: <FaTwitter /> }
};

const TeamMember = ({ data }) => {
  return(
    <div className='team-static'>
      {data?.relationships?.picture?.relationships && <div className='thumbnail'>
        <MediaImage media={data.relationships.picture.relationships.image} alt={data.relationships.picture.media?.alt} title={data.relationships.picture.media?.title} />
      </div>}
      <div className="inner">
        <div className="content">
          <h4 className="title">{data.name}</h4>
          {data?.designation && <p className="designation">{data.designation}</p>}
        </div>
        {data?.socialLinks?.platforms && <ul className="social-transparent liststyle d-flex" >
          {Object.keys(data.socialLinks.platforms).map((social, index) => {
            const socialData = socialLinks[social] || {};
            const socialTeamData = data.socialLinks.platforms[social];
            if (socialTeamData.value) {
              return <li key={index}>
                <a href={`${socialData.url}/${socialTeamData.value}`} rel="noopener noreferrer" target="_blank">{socialData.icon}</a>
              </li>;
            }
          })}
        </ul>}
      </div>
    </div>
  );
};

TeamMember.propTypes = {
  data: PropTypes.shape({
    name: PropTypes.string.isRequired,
    designation: PropTypes.string,
    socialLinks: PropTypes.shape({
      platforms: PropTypes.shape({
        drupal: PropTypes.shape({ value: PropTypes.string }),
        github: PropTypes.shape({ value: PropTypes.string }),
        instagram: PropTypes.shape({ value: PropTypes.string }),
        linkedin: PropTypes.shape({ value: PropTypes.string }),
        twitter: PropTypes.shape({ value: PropTypes.string }),
      })
    }),
    relationships: PropTypes.shape({
      picture: MediaImageType,
    })
  })
};

export default TeamMember;
