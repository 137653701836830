import { useRef, useState, useEffect } from 'react';
import ResizeObserver from 'resize-observer-polyfill';

/**
 * Hook obtained from react-spring.
 */
export default function useMeasure() {
  const ref = useRef();
  const isActive = useRef(true);
  const [bounds, set] = useState({ left: 0, top: 0, width: 0, height: 0 });
  const [ro] = useState(() => new ResizeObserver(([entry]) => isActive.current && set(entry.contentRect)));
  useEffect(() => {
    ro.observe(ref.current);
    return () => {
      isActive.current = false;
      ro.disconnect;
    };
  }, [ro]);
  return [{ ref }, bounds];
}
