import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

import Project from './Project';

const Projects = ({ data }) => {
  return (
    <div className="fix">
      <div className="portfolio-area ptb--120 bg_color--1">
        <div className="portfolio-sacousel-inner">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title text-center service-style--3 mb--30 mb_sm--0">
                  {data?.title && <h2 className="title">{data.title}</h2>}
                  {data?.summary?.processed && <div dangerouslySetInnerHTML={{ __html: data.summary.processed }}></div>}
                </div>
              </div>
            </div>
            <div className="row">
              {data.relationships.projects.map((project, index) => (
                <div className={`${'col-lg-4 col-md-6 col-sm-6 col-12'}`} key={index}>
                  <Project data={project} />
                </div>
              ))}
            </div>
            <div className="row">
              <div className="col-lg-12">
                <div className="view-more-btn mt--60 mt_sm--30 text-center">
                  <Link to='/projects' className="rn-button-style--2 btn-solid">View More</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Projects.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string,
    summary: PropTypes.shape({
      processed: PropTypes.string
    }),
    relationships: PropTypes.shape({
      projects: PropTypes.array
    })
  })
};

export default Projects;
