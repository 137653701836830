import React from 'react';
import PropTypes from 'prop-types';

import { MediaImageType } from '../../types';
import MediaImage from '../Elements/MediaImage';

const ImageText = ({ data }) => {
  return (
    <section id="about" className="section ImageText">
      <div className="container">
        <div className="">
          <div className="">
            {data?.relationships?.image?.relationships && <div className="thumbnail">
              <MediaImage className="w-100" media={data.relationships.image.relationships?.image} alt={data.relationships.image.media?.alt} title={data.relationships.image.media?.title} />
            </div>}
          </div>
          <div className="">
            <div className="about-inner inner">
              <div className="section-title">
                <h2 className="title-wrapper">{data.title}</h2>
                <div className="description" dangerouslySetInnerHTML={{ __html: data.body.processed }}></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

  );
};

ImageText.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string,
    body: PropTypes.shape({
      processed: PropTypes.string
    }),
    relationships: PropTypes.shape({
      image: MediaImageType
    })
  })
};

export default ImageText;
