import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';

const DrupalLink = ({ title, url, classes}) => {
  const removeInternalPrefix = (url) => {
    return url.replace('internal:','');
  };

  const isInternalUrl = (url) => {
    return url.startsWith('internal:');
  };

  if (isInternalUrl(url)) {
    const urlRemoved = removeInternalPrefix(url);
    return <Link className={classes} to={urlRemoved}>{title}</Link>;
  } else {
    return <a className={classes} href={url} rel="noopener noreferrer" target='_blank'>{title}</a>;
  }
};

DrupalLink.propTypes = {
  title: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  classes: PropTypes.string.isRequired,
};

export default DrupalLink;
