import React from 'react';
import PropTypes from 'prop-types';

import DrupalLink from '../../Elements/DrupalLink';
import MediaImage from '../../Elements/MediaImage';
import { MediaImageType } from '../../../types';

const Banner = ({ data }) => {
  return (
    <div className={`cover-full text-center ${data.relationships.classes.map(customClass => customClass.name).join(' ')}`} id={data.id}>
      <div className="cover-full-inner">
        <div className="cover-full-content">
          {data?.kicker?.processed && <div dangerouslySetInnerHTML={{ __html: data.kicker.processed }} className="kicker"></div>}
          {data?.heading?.processed && <div dangerouslySetInnerHTML={{ __html: data.heading.processed }} className="title"></div>}
          {data?.summary?.processed && <div dangerouslySetInnerHTML={{ __html: data.summary.processed }} className="description"></div>}
          {data?.link?.uri && <div className="slide-btn">
            <DrupalLink classes="btn btn-big" url={data.link.uri} title={data.link.title} />
          </div>}
        </div>
        <div className="cover-full-img">
          {data.relationships.images.map((image, index) => {
            return <MediaImage flex key={index} media={image.relationships?.image} alt={image.media.alt} title={image.media.title} />;
          })}
        </div>
        <div className="cover-full-background">
          {data.relationships.backgroundImages.map((image, index) => {
            return <MediaImage key={index} media={image.relationships?.image} alt={image.media.alt} title={image.media.title} />;
          })}
        </div>
      </div>
    </div>
  );
};

Banner.propTypes = {
  data: PropTypes.shape({
    kicker: PropTypes.shape({
      processed: PropTypes.string
    }),
    heading: PropTypes.shape({
      processed: PropTypes.string
    }),
    summary: PropTypes.shape({
      processed: PropTypes.string
    }),
    link: PropTypes.shape({
      title: PropTypes.string,
      uri: PropTypes.string
    }),
    id: PropTypes.string,
    relationships: PropTypes.shape({
      images: PropTypes.arrayOf(MediaImageType),
      backgroundImages: PropTypes.arrayOf(MediaImageType),
      classes: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string
      }))
    })
  })
};

export default Banner;
