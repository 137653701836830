import React, { useState } from 'react';
import PropTypes from 'prop-types';
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';

const Facts = ({ data }) => {
  const [didViewCountUp, setDidViewCountUp] = useState(false);

  const updateVisibility = isVisible => {
    if (isVisible) {
      setDidViewCountUp(true);
    }
  };

  return (
    <div className="row">
      {data.map((value, index) => (
        <div className="counterup_style--1 col-lg-4 col-md-4 col-sm-6 col-12" key={index}>
          <h5 className="counter">
            <VisibilitySensor onChange={updateVisibility} offset={{top:10}} delayedCall>
              <CountUp end={didViewCountUp ? value.number : 0} />
            </VisibilitySensor>
          </h5>
          {value?.summary?.processed && <div className="description" dangerouslySetInnerHTML={{ __html: value.summary.processed }}></div>}
        </div>
      ))}
    </div>
  );
};

Facts.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    number: PropTypes.number.isRequired,
    summary: PropTypes.shape({
      processed: PropTypes.string
    })
  }))
};

export default Facts;
