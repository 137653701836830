import React from 'react';
import PropTypes from 'prop-types';

import { MediaImageType } from '../../types';
import MediaImage from '../Elements/MediaImage';

const LogosSet = ({ data }) => {
  console.log(data);
  return (
    <div className="rn-brand-area brand-separation bg_color--5 pb--120">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="section-title text-center mb--30">
              {data?.summary?.processed && <div className="description" dangerouslySetInnerHTML={{ __html: data.summary.processed }}></div>}
            </div>
            <ul className="brand-style-2" >
              {data.relationships.logos.map((logo, index) => (
                <li key={index}>
                  <MediaImage media={logo.relationships?.image} alt={logo.media?.alt} title={logo.media?.title} />
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

LogosSet.propTypes = {
  data: PropTypes.shape({
    summary: PropTypes.shape({
      processed: PropTypes.string
    }),
    relationships: PropTypes.shape({
      logos: PropTypes.arrayOf(MediaImageType)
    })
  })
};

export default LogosSet;
