import React from 'react';
import PropTypes from 'prop-types';
import BackgroundImage from 'gatsby-background-image';

import DrupalLink from '../../Elements/DrupalLink';
import { MediaImageType } from '../../../types';

const CoverFull = ({ data }) => {
  return (
    <BackgroundImage
      Tag="div"
      className="cover-full"
      fluid={data.relationships.image.relationships.image.localFile.childImageSharp.fluid}
      backgroundColor={`#040e18`}
      >
      <div className={`cover-full-inner text-center ${data.relationships.classes.map(customClass => customClass.name).join(' ')}`} id={data.id}>
        <div className="cover-full-content">
          {data?.kicker?.processed && <div dangerouslySetInnerHTML={{ __html: data.kicker.processed }} className="kicker"></div>}
          {data?.heading?.processed && <div dangerouslySetInnerHTML={{ __html: data.heading.processed }} className="title"></div>}
          {data?.summary?.processed && <div dangerouslySetInnerHTML={{ __html: data.summary.processed }} className="description"></div>}
          {data?.link?.uri && <div className="slide-btn">
            <DrupalLink classes="rn-button-style--2 btn btn-big" url={data.link.uri} title={data.link.title} />
          </div>}
        </div>
      </div>
    </BackgroundImage>
  );
};

CoverFull.propTypes = {
  data: PropTypes.shape({
    kicker: PropTypes.shape({
      processed: PropTypes.string
    }),
    heading: PropTypes.shape({
      processed: PropTypes.string
    }),
    summary: PropTypes.shape({
      processed: PropTypes.string
    }),
    link: PropTypes.shape({
      title: PropTypes.string,
      uri: PropTypes.string
    }),
    id: PropTypes.string,
    relationships: PropTypes.shape({
      image: MediaImageType,
      classes: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string
      }))
    })
  })
};

export default CoverFull;
