import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useSpring, animated } from 'react-spring';
import { useGesture } from 'react-use-gesture';

import useMeasure from '../../hooks/use-measure';
import { getSectionComponent } from '../../helpers/sectionsHelper';
import { isEnterOrSpace } from '../../helpers/isEnterOrSpace';

const PageSections = ({ sections }) => {
  console.log('sections', sections);

  const [currentSection, setCurrentSection] = useState(0);
  const [bindMeasure, { height }] = useMeasure();

  useEffect(() => {
    console.log('xxxx');
    function handleKeyDown(keyboardEvent) {
      console.log(keyboardEvent);
      if (keyboardEvent.key === 'ArrowDown') {
        nextSection();
      }

      if (keyboardEvent.key === 'ArrowUp') {
        previousSection();
      }
    }

    window.addEventListener('keydown', handleKeyDown);

    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [nextSection, previousSection]);

  const previousSection = useCallback(() => {
    setCurrentSection(prevSection => (prevSection > 0 ? prevSection - 1 : 0));
  }, []);

  const nextSection = useCallback(() => {
    setCurrentSection(prevSection => prevSection + 1 < sections.length ? (prevSection + 1) : prevSection);
  }, [sections.length]);

  const moveSection = (movingUpwards) => {
    movingUpwards ? previousSection() : nextSection();
  };

  const onWheelEnd = ({ direction: [, yDirection], wheeling}) => {
    if (!wheeling) {
      moveSection(yDirection < 0);
    }
  };

  const onDragEnd = ({ direction: [, yDirection], distance }) => {
    if (distance) {
      moveSection(yDirection > 0);
    }
  };

  const bindGestures = useGesture({
    onWheelEnd: state => onWheelEnd(state),
    onDragEnd: state => onDragEnd(state),
  });

  const springProps = useSpring({
    transform: `translate3d(0px, ${-1 * currentSection * height}px, 0px)`,
  });

  const memoizedComponents = useMemo(() => {
    return sections
      .map(section => {
        const SectionComponent = getSectionComponent(section);
        return {
          component: SectionComponent && React.memo(SectionComponent),
          data: section
        };
      })
      .filter(sectionComponent => Boolean(sectionComponent.component));
  }, [sections]);

  return (
    <div className="slider-fullscreen">
      <animated.div
        {...bindMeasure}
        {...bindGestures()}
        className="slide-wrapper"
        style={{ ...springProps }}
      >
        {memoizedComponents.map((MemoizedSection, index) => {
          return (
            <section key={index} className={index === currentSection ? 'active' : ''}>
              <MemoizedSection.component data={MemoizedSection.data} />
            </section>
          );
        })}
      </animated.div>
      <div className="slider-bullets">
        {memoizedComponents.map((_, index) => {
          return (
            <button
              key={index}
              className={`slider-bullet ${currentSection === index ? 'active' : ''}`}
              onClick={() => setCurrentSection(index)}
              onKeyDown={(e) => isEnterOrSpace(e) && setCurrentSection(index)}>
              {index}
            </button>
          );
        })}
      </div>
    </div>
  );
};

PageSections.propTypes = {
  sections: PropTypes.arrayOf(PropTypes.shape({
    type: PropTypes.string
  }))
};

export default PageSections;
