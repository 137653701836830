import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';

import { MediaImageType } from '../../../types';

const PortfolioList = ({ data }) => {
  console.log(data);
  return(
    <div className={`portfolio ${'text-center mt--40'}`}>
      <div className="thumbnail-inner">
        <BackgroundImage
          Tag="div"
          className="thumbnail"
          fluid={data.relationships.previewImage.relationships.image.localFile.childImageSharp.fluid}
          backgroundColor={`#040e18`}
        >
        </BackgroundImage>
        <div className={`bg-blr-image ${'value.image'}`}></div>
      </div>
      <div className="content">
        <div className="inner">
          {data?.title && <h4><Link to={data.path.alias}>{data.title}</Link></h4>}
          <div className="portfolio-button">
            <Link to={data.path.alias} className="rn-btn">View Details</Link>
          </div>
        </div>
      </div>
    </div>
  );
};

PortfolioList.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string,
    path: PropTypes.shape({
      alias: PropTypes.string
    }),
    relationships: PropTypes.shape({
      previewImage: MediaImageType
    })
  })
};

export default PortfolioList;
